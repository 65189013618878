<template>
    <div class="itemOnKdsSet">
        
        <div class="flex-box">
            <div class="left-table">
                <el-select v-model="showType" default-first-option>
                    <el-option :value="0" label="按菜品类别"></el-option>
                    <el-option :value="1" label="按出单位置"></el-option>
                </el-select>
                <div class="scroll-box">
                    <el-tree
                        ref="treeRef"
                        class="filter-tree"
                        :data="showType==0?treeData:kitTreeData"
                        :expand-on-click-node="false"
                        highlight-current
                        default-expand-all
                        @current-change="currentChange"
                    />
                </div>
            </div>
            <div class="right-table ">
                <div class="filter-box">
                    <div class="from-box">
                        <div class="lable-txt">岗位:</div>
                        <div class="from-input" style="width: 180px;">
                            <el-select v-model="planInfo" default-first-option value-key="KitPostID">
                                <el-option v-for="item in KitPostEquipments" :key="item" :value="item" :label="item.KitPostName"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="from-box">
                        <div class="lable-txt">设备:</div>
                        <div class="from-input" style="width: 180px;">
                            <el-select v-model="equipmentIds" placeholder="全部" multiple collapse-tags>
                                <el-option 
                                    v-for="item in postEquipmentArr"
                                    :key="item.Equipment_ID"
                                    :label="item.Equipment_Name"
                                    :value="item.Equipment_ID"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="from-box">
                        <el-checkbox v-model="isUnAssociated" label="仅显示未关联KDS的菜品" ></el-checkbox>
                    </div>
                </div>
                <p class="remark">注：本设置界面只显示KDS需要出现的菜品</p>
                <div class="table-box grey-table">
                    <el-table class="el-table--scrollable-y" style="width: 100%;height:100%"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                        ref="elTable"
                        :data="pageTableData"
                        highlight-current-row
                        @selection-change="selectFood"
                        :row-class-name="tableRowClassName"
                        :cell-class-name="$parent.setClassName"
                        @cell-dblclick="$parent.dblclick"
                    >
                        <el-table-column class-name="is-fixed" label="选择" type="selection" width="40" ></el-table-column>
                        <el-table-column class-name="is-fixed" prop="Food_Code" label="菜品编码" min-width="100" align="left">
                            <template #default="scope">
                                <span v-copyText>{{scope.row.Food_Code}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column class-name="is-fixed" prop="Food_Name" label="菜品名称" min-width="100" align="left">
                            <template #default="scope">
                                <span v-copyText>{{scope.row.Food_Name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column class-name="is-fixed" prop="Food_Price" label="售价" min-width="80" >
                            <template #default="scope">
                                {{scope.row.Food_Price}}/{{scope.row.Food_Size}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="kds.Equipment_Name" v-for="kds in equipmentList" :key="kds">
                            <el-table-column min-width="80" v-for="item in kds.KdsProjectDetails" :key="item">
                                <template #header>
                                    <el-dropdown trigger="click" :max-height="408">
                                        <span class="el-dropdown-link" >
                                        {{item.Screen_Name}}<span class="kit_batch" >[批量]</span>
                                        </span>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item @click="batch(item,true)">是</el-dropdown-item>
                                                <el-dropdown-item @click="batch(item,false)">否</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                                <template #default="scope">
                                    <el-checkbox v-model="editFoodLinkKds[scope.row.Food_ID][item.Screen_AutoID]" @click="rowSelect(scope.row)"></el-checkbox>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="search-box">
                    <key-board-input class="search"
                        type="text" v-model="searchTxt" placeholder="菜品名称、编码或助记码模糊检索"
                            isBlurClose
                    ></key-board-input>
                    <div class="float-right">
                        <span class="last-page iconfont icon-fangxiangquan-left" @click="billPage()"></span>
                        <span class="page-number">{{ tablePageIndex+1 }}/{{ tablePageSum }}页</span>
                        <span class="next-page iconfont icon-fangxiangquan-right" @click="billPage(1)" ></span>
                        <div class="page-bnt">
                            <el-select v-model="pageSize"  >
                                <el-option :value="20" label="20条/页"></el-option>
                                <el-option :value="30" label="30条/页"></el-option>
                                <el-option :value="50" label="50条/页"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <modal-load :isShow="isCopyShow">
            <modal class="copyModal" :isShow="isCopyShow">
                <div class="header">复制</div>
                <div class="modal-body">
                    <div class="li-box">
                        <div class="li-flex">
                            <span class="lable-text">标准设备屏幕：</span>
                            <div class="from-input">
                                <el-select v-model="copyId" placeholder="请选择">
                                    <el-option 
                                        v-for="item in kdsScreenList"
                                        :key="item.Screen_AutoID"
                                        :label="item.Equipment_Name+'-'+item.Screen_Name"
                                        :value="item.Screen_AutoID"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="li-flex">
                            <span class="lable-text">待复制设备屏幕：</span>
                            <div class="from-input">
                                <el-select v-model="copyScreenIds" placeholder="请选择" multiple collapse-tags @remove-tag="copyScreenIds=[]">
                                    <el-option 
                                        v-for="item in copyKdsScreenList"
                                        :key="item.Screen_AutoID"
                                        :label="item.Equipment_Name+'-'+item.Screen_Name"
                                        :value="item.Screen_AutoID"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="remark">说明: 将[标准设备屏幕]的菜品复制到[待复制设备屏幕]</div>
                    </div>
                </div>
                <div class="footer-box">
                    <button class="btn" @click="isCopyShow=false">取消</button>
                    <button class="btn selected" @click="copy()"> 复制</button>
                </div>
            </modal>
        </modal-load>
    </div>
</template>

<script>
import { deepClone } from '/src/common'
/**菜品关联KDS设置 */
export default {
    name:"itemOnKdsSet",
    props:{
        /**所有菜品 */
        PosFoods:{
            typeof:Array,
            default:null
        },
        /**菜品分类 */
        foodMainCategory:{
            typeof:Array,
            default:null
        },
        /**出单位置 */
        KitIssuePlace:{
            typeof:Array,
            default:null
        },
        /**KDS岗位设备信息 */
        KitPostEquipments:{
            typeof:Array,
            default:null
        }
    },
    computed:{
        treeData(){
            let tree=[];
            this.foodMainCategory?.forEach(category=>{
                let children=[];
                category.FoodSubCategorys?.forEach(sub=>{
                    children.push({
                        id:sub.SubCategory_ID,
                        label:(sub.SubCategory_Code+"-"+sub.SubCategory_Name).replace(/^[-]/,""),
                        SubCategory_ID:sub.SubCategory_ID,
                        //PosFoods:sub.PosFoods
                    })
                });
                tree.push({
                    id:category.MainCategory_ID,
                    label:(category.MainCategory_Code+"-"+category.MainCategory_Name).replace(/^[-]/,""),
                    children:children
                });
            })
            return [{label:"全部",children:tree,id:0}];
        },
        kitTreeData(){
            let tree=[];
            this.KitIssuePlace?.forEach(it=>{
                tree.push({
                    id:it.IssuePlace_AutoID,
                    label:it.IssuePlace_Code+"-"+it.IssuePlace_Name,
                });
            })
            return [{label:"全部",children:tree,id:0}];
        },
        tableData(){
            if(this.showType==0){//按分类
                if(!this.currentNode){
                    return this.PosFoods;
                }
                else if(this.currentNode?.children){
                    let foods=[];
                    let category= this.foodMainCategory.find(t=>t.MainCategory_ID==this.currentNode.id);
                    if(category){
                        category.FoodSubCategorys?.forEach(f=>{
                            foods=foods.concat(f.PosFoods);
                        });
                    }
                    return foods;
                }
                return this.PosFoods?.filter(t=>t.SubCategory_ID==this.currentNode.id);
            }else{//按出单位置
                if(!this.currentNode){
                    return this.PosFoods;
                }else{
                    return this.PosFoods?.filter(t=>t.KitPlace_ID==this.currentNode.id);
                }
            }
        },
        /**筛选数据 */
        tableFilter(){
            let data=this.tableData||[];
            if(this.isUnAssociated){
                let types=[];
                this.equipmentList.forEach(kds=>{//所有设备屏幕
                    kds?.KdsProjectDetails.forEach(item=>{
                        types.push(item);
                    })
                })
                data=data.filter(t=> !types.some(item=>(this.oldFoodLinkKds && this.oldFoodLinkKds[t.Food_ID][item.Screen_AutoID])) )
            }
            if(this.searchTxt.trim()!=''){
                let MenuTxt=this.searchTxt.trim().toLowerCase();
                data=data.filter(t=>(t.Food_Code||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Food_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.Food_PinYin||"").toLowerCase().indexOf(MenuTxt)>=0);
            }
            return data;
        },
        /**分页数据 */
        pageTableData(){
            return this.tableFilter.slice(this.tablePageIndex*this.pageSize,(this.tablePageIndex+1)*this.pageSize);
        },
        tablePageSum(){
            let length=this.tableFilter.length;
            if(length>1){
                return Math.ceil(length/this.pageSize);
            }
            return 1;
        },
        /**KDS 站点 */
        equipmentList(){
            let data=[];
            if(this.equipmentIds?.length>0){
                data=this.postEquipmentArr.filter(it=>this.equipmentIds.indexOf(it.Equipment_ID)>=0);
            }else{
                data=this.postEquipmentArr;
            }
            return data;
        },
        /**岗位站点 中的设备数据 */
        postEquipmentArr(){
            let data=[];
            if(this.planInfo){
                return this.planInfo.KdsProjects;
            }
            return data;
        },
        /**kds 标准屏幕选择 */
        kdsScreenList(){
            let data=[];
            this.postEquipmentArr.forEach(eq=>{
                eq.KdsProjectDetails?.forEach(it=>{
                    data.push(Object.assign({Equipment_Name:eq.Equipment_Name},it));
                })
            })
            return data;
        },
        /**待复制 可选kds屏幕 排除标准屏幕  */
        copyKdsScreenList(){
            return this.kdsScreenList?.filter(it=>it.Screen_AutoID!=this.copyId);
        }
    },
    watch:{
        pageSize(newVal){
            this.$cacheData.set("itemOnKdsSet_pageSize",newVal);
        },
        KitPostEquipments:{
            handler(){//默认第一个岗位
                if(this.KitPostEquipments?.length>0 && !this.KitPostEquipments.some(it=>it.KitPostID==this.planInfo?.KitPostID)){
                    this.planInfo=this.KitPostEquipments[0];
                }
            },
            immediate:true,
        },
        tablePageSum(newVal){
            if(newVal<=this.tablePageIndex){
                this.tablePageIndex=0;
            }
        },
        PosFoods:{
            handler(){
                this.setFoodLinkKds();
            },
            immediate:true,
        },
        equipmentList:{
            handler(){
                this.setFoodLinkKds();
            },
            immediate:true,
        },
        copyId(){
            if(this.copyId){
                let index=this.copyEquipmentIds?.indexOf(this.copyId);
                if(index>=0){
                    this.copyEquipmentIds.splice(index,1);
                }
            }
        }
    },
    data(){
        let pageSize= this.$cacheData.get("itemOnKdsSet_pageSize")||20;
        return {
            /**0按菜品类别  1按出单位置*/
            showType:0,
            tablePageIndex:0,
            pageSize:pageSize,
            //选中的节点
            currentNode:null,
            //搜索数据
            searchTxt:"",
            //岗位
            planInfo:null,
            //设备
            equipmentIds:"",
            /**菜品关联KDS屏幕 */
            foodLinkKdsScreens:null,
            /*勾选 菜品关联KDS屏幕数据*/
            editFoodLinkKds:{},
            /**记录上一次 菜品关联KDS屏幕数据 */
            oldFoodLinkKds:{},
            /**勾选要修改的菜品 */
            checkFoods:[],
            /**仅显示未关联KDS的菜品 */
            isUnAssociated:false,
            //一键复制弹层
            isCopyShow:false,
            /**标准设备屏幕id */
            copyId:null,
            /**待复制的设备屏幕id */
            copyScreenIds:[]
        }
    },
    updated(){
        this.$nextTick(()=>{
            let header=this.$refs.elTable?.$el?.querySelector(".el-table__header");
            if(header && this.isVisible(header)){
                let width=0;
                let table=this.$refs.elTable?.$el;
                Array.from(header.children[0].children).forEach((it,i)=>{
                    if(i<3){
                        width+=it.clientWidth;
                        table.style["--fiexd"+(i+1)]=width+"px";
                    }
                })
            }
            
        })
    },
    mounted(){
    },
    methods:{
        isVisible(elem){
            return !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length )
        },
        currentChange(node){
            if(node.id!=0){
                this.currentNode=node;
            }else{
                this.currentNode=null;
            }
            
        },
        /**设置类名 */
        tableRowClassName({ row }){
            let num=0;
            return this.equipmentList?.some(it=>it.KdsProjectDetails.some(item=>{
                if(this.editFoodLinkKds[row.Food_ID][item.Screen_AutoID]){
                    num++;
                }
                return num>1;
            }))?"edit":""
        },
        //勾选的菜品
        selectFood(currentRow){
            this.checkFoods=currentRow;
        },
        /**修改时 勾选行 */
        rowSelect(row){
            this.$refs.elTable?.toggleRowSelection(row,true);
        },
        /**批量勾选 */
        batch(type,isBo){
            let isSelect=this.checkFoods?.length>0;
            this.pageTableData?.forEach(food=>{
                if(isSelect){
                    if(this.checkFoods.some(it=>it==food)){
                        this.editFoodLinkKds[food.Food_ID][type.Screen_AutoID]=isBo;
                    }
                }else{
                    this.editFoodLinkKds[food.Food_ID][type.Screen_AutoID]=isBo;
                    this.rowSelect(food);
                }
            })
        },
        /**数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.tablePageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                this.tablePageIndex>0?this.tablePageIndex--:this.tablePageIndex=0;
            }
        },
        setFoodLinkKds(){
            this.$refs.elTable?.clearSelection();//清空勾选
            let foodLinkKds={};
            if(this.PosFoods?.length>0 && this.equipmentList?.length>0){
                this.PosFoods?.forEach(it=>{
                    foodLinkKds[it.Food_ID]={};
                    this.equipmentList?.forEach(eq=>{
                        eq.KdsProjectDetails.forEach(item=>{//屏幕
                            if(item.Is_ShowAllIssuePosition){//是否显示所有出单位置， true: 全部
                                foodLinkKds[it.Food_ID][item.Screen_AutoID]= it.KitPlace_ID?true:false;//菜品有绑出单位置时默认勾选
                            }else{
                                foodLinkKds[it.Food_ID][item.Screen_AutoID]= item.IssuePositions?.indexOf(it.KitPlace_ID)>=0;
                            }
                        })
                        
                    })
                })

                this.foodLinkKdsScreens?.forEach(it=>{
                    if(foodLinkKds[it.Food_ID]){
                        foodLinkKds[it.Food_ID][it.KitScreen_ID]=it.Chosen_YN;
                    }
                })
            }
            this.editFoodLinkKds=foodLinkKds;
            this.oldFoodLinkKds=deepClone(foodLinkKds);
        },
        loadData(isRefresh){
            if(!isRefresh && this.foodLinkKdsScreens?.length>0){
                return;
            }
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.KDS.GetFoodLinkKdsScreens",{
                Operate_User:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.foodLinkKdsScreens=d.ResponseBody;
                    this.setFoodLinkKds();
                }
            }).catch(e=>{
                loading.close();
                console.log('e:',e)
                this.$alert('获取菜品关联出单类型数据失败', "提示", {confirmButtonText: "确定"});
            })
        },
        /**保存 菜品出单位置数据 */
        save(){
            if(!this.checkFoods?.length>0){
                this.$message.warning('请先勾选要保存的数据');
                return;
            }

            let foodsKit=[];
            this.checkFoods?.forEach(food=>{

                this.equipmentList.forEach(kds=>{
                    kds.KdsProjectDetails.forEach(item=>{
                        let json={
                            Food_ID:food.Food_ID,
                            KitScreen_ID:item.Screen_AutoID,
                            Chosen_YN:this.editFoodLinkKds[food.Food_ID] && this.editFoodLinkKds[food.Food_ID][item.Screen_AutoID]===true
                        }
                        foodsKit.push(json);
                        
                        if(food.MultipleSizeFoods?.length>0){//多规格
                            food.MultipleSizeFoods.forEach(it=>{
                                if(it.Food_ID!=food.Food_ID){
                                    foodsKit.push({
                                        Food_ID:it.Food_ID,
                                        KitScreen_ID:json.KitScreen_ID,
                                        Chosen_YN:json.Chosen_YN
                                    });
                                }
                            })
                        }
                    })
                })
            })
            
            if(foodsKit.length==0){
                this.$message.warning('数据无修改！请先修改在进行保存');
                return;
            }

            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.KDS.SetFoodLinkKdsScreens",{
                FoodsLinkKitScreens:foodsKit,
                Operate_User:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("数据保存成功");
                    this.$refs.elTable?.clearSelection();
                    this.oldFoodLinkKds=deepClone(this.editFoodLinkKds);
                    if(this.foodLinkKdsScreens?.length>0){
                        foodsKit?.forEach(food=>{
                            let oldFood=this.foodLinkKdsScreens.find(f=>f.Food_ID==food.Food_ID && f.KitScreen_ID==food.KitScreen_ID);
                            if(oldFood){
                                oldFood.Chosen_YN=food.Chosen_YN;
                            }else{
                                this.foodLinkKdsScreens.push(food);
                            }
                        })
                    }else{
                        this.foodLinkKdsScreens=foodsKit;
                    }
                    
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据保存失败："+e);
                console.log("数据保存失败："+e);
            })
        },
        /**显示复制 */
        copyShow(){
            this.isCopyShow=true;
            this.copyId=null;
            this.copyScreenIds=[];
        },
        /**复制 */
        copy(){
            if(!this.copyId){
                this.$message.warning('标准设备屏幕未选择！');
                return;
            }
            if(this.copyScreenIds.length==0){
                this.$message.warning('待复制设备屏幕未选择！');
                return;
            }
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.KDS.CopyKdsScreenLinkFoods",{
                KitScreen_ID:this.copyId,
                KitScreen_IDList:this.copyScreenIds,
                Operate_User:userInfo?.Login_Name,
            }).then((d)=>{
                console.log(d)
                this.isCopyShow=false;
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("数据保存成功");
                    this.loadData(true);//刷新数据
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据保存失败："+e);
                console.log("数据保存失败："+e);
            })
        }
    }
}
</script>

<style lang="scss">
.copyModal{
    font-size: 12px;
    color: #000000;

    .modal-dialog{ 
        width: 450px;
        max-width: 450px;
       
        .modal-content{
            right: inherit;
            width: 100%;
            border-radius: 8px;
            padding-bottom: 24px;
        }
    }
 
    .header{
        background: #DCE2EA;
        height: 42px;
        line-height: 42px;
        padding-left: 20px;
        border-radius: 8px;
        border-bottom-left-radius:0 ;
        border-bottom-right-radius:0 ;
        font-size: 15px;
        color: #333333;
    }

    .flex-box{
        display: flex;
    } 

    .modal-body{
        padding:20px 15px;
        padding-bottom: 40px;
        font-size: 14px;
        
        .li-box{
            margin:10px 0;

            .li-flex{
                display: flex;
                align-items: center;
                margin: 14px 0;

                .lable-text{
                    width:120px;
                }

                .from-input{
                    flex:1;
                    color: #487BB1;

                    .el-select{
                        width: 100%;
                    }
                }
            }

            .remark{
                
                color: red;
            }
        }
    }

    .footer-box{
        padding: 0 15px;
        text-align: center;

        .btn{
            background: #E8E8E8;
            border: 1px solid #D5D7DB;
            border-radius: 4px;
            width: 120px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            color: #487BB1;
            padding: 0;

            &:first-child{
                float:left;
            }

            &:last-child{
                float:right;
                margin-right: 0;
            }

            &.selected{
                background: #487BB1;
                color:#fff;
            }
        }
    }
}
</style>